import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext } from '../../context';


export const GeneralUpdateComponent = () => {
  const {
    roleGeneralIsEditing,
    formValue,
    formDirty,
    formIsSubmitting,
    onSubmit,
    changeFormValue,
    errorSet,
    roleGeneralFormClose,
  } = useMainContext();

  if (formValue === null || !roleGeneralIsEditing) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSubmitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={roleGeneralFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        label="Название"
        name="name"
        onChange={changeFormValue}
        error={errorSet('name')}
        value={formValue.name}
      />
    </FormCenteredBlock>
  );
};
