import { useNavigate } from 'react-router-dom';

import {
  WidgetLinkBrick,
  AdminGuardIcon,
  ManageAccountsIcon,
  WidgetsListBlock,
  DescriptionIcon,
} from '@app/components';
import { useAuthStore } from '@app/stores';


export const HomeFeature = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  if (user === null) return (
    <h1>Вы не авторизованы</h1>
  );

  const role = user.role;

  const rolesButtonVisible = role.ROLE_CREATE === 'ALL' || role.ROLE_READ === 'ALL' || role.ROLE_UPDATE === 'ALL';
  const usersButtonVisible = role.USER_CREATE === 'ALL' || role.USER_READ === 'ALL' || role.USER_UPDATE === 'ALL';
 
  return (
    <WidgetsListBlock>
      {rolesButtonVisible && (
        <WidgetLinkBrick
          title="Роли"
          onClick={() => navigate('/role-list')}
          icon={<AdminGuardIcon />}
        />
      )}
      {usersButtonVisible && (
        <WidgetLinkBrick
          title="Пользователи"
          onClick={() => navigate('/user-list')}
          icon={<ManageAccountsIcon />}
        />
      )}
      <WidgetLinkBrick
        title="Страницы"
        onClick={() => navigate('/page-list')}
        icon={<DescriptionIcon />}
      />
      <WidgetLinkBrick
        title="Аналитические задачи"
        onClick={() => navigate('/task-list')}
        icon={<DescriptionIcon />}
      />
    </WidgetsListBlock>
  );
};
