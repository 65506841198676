import {
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';


export const RoleScriptsComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    roleOneIsLoading,
    roleScriptsIsEditing,
    roleScriptsFormOpen,
    roleScriptsFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Матрица доступа к скриптам"
      extra={
        <>
          {!roleScriptsIsEditing && <ButtonEditBlock onClick={() => roleScriptsFormOpen()} />}
          {roleScriptsIsEditing && <ButtonCancelBlock onClick={() => roleScriptsFormClose()} buttonIcon />}
        </>
      }
      loading={roleOneIsLoading}
      mb12
    >
      {children}
    </CardDataComposition>
  );
}
