import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  CardActionsBrick,
  DividerHorizontalBrick,
  FieldSelectBlock,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import { useMainContext } from '../../context';
import React from 'react';
import { scriptListGet } from '@app/helpers';
import { RoleAccessType } from '@app/types';


export const RoleScriptsUpdateComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSubmitting,
    roleScriptsIsEditing,
    roleAccessFormClose,
    onSubmit,
    changeFormValue,
  } = useMainContext();

  if (formValue === null || !roleScriptsIsEditing) return null;

  return (
    <React.Fragment>
      <TableContainerBrick style={{ overflow: 'visible' }}>
        <TableBrick>
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>Скрипт</TableCellBrick>
              <TableCellBrick>Доступ</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            {scriptListGet().map((scriptItem) => {
              const key = 'SCRIPT_' + scriptItem.value;
              const accessValue: RoleAccessType = (formValue as any)[key];
                          
              return (
                <TableRowBrick
                  key={key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCellBrick>{scriptItem.label}</TableCellBrick>
                  <TableCellBrick>
                    <FieldSelectBlock
                      value={accessValue}
                      name={key}
                      label="Доступ"
                      onChange={changeFormValue}
                      items={[
                        { value: 'NEVER', label: 'Нет' },
                        { value: 'ALL', label: 'Да' },
                      ]}
                      hideError
                    />
                  </TableCellBrick>
                </TableRowBrick>
              );
            })}
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
      <DividerHorizontalBrick />
      <CardActionsBrick>
        <ButtonSaveBlock
          loading={formIsSubmitting}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonCancelBlock onClick={roleAccessFormClose}/>
      </CardActionsBrick>
    </React.Fragment>
  );
};
