import {
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';


export const RoleAccessComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    roleOneIsLoading,
    roleAccessIsEditing,
    roleAccessFormOpen,
    roleAccessFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Матрица доступа"
      extra={
        <>
          {!roleAccessIsEditing && <ButtonEditBlock onClick={() => roleAccessFormOpen()} />}
          {roleAccessIsEditing && <ButtonCancelBlock onClick={() => roleAccessFormClose()} buttonIcon />}
        </>
      }
      loading={roleOneIsLoading}
      mb12
    >
      {children}
    </CardDataComposition>
  );
}
