import { RoleAccessType, RoleType, TaskScriptType } from '@app/types';

const scriptList = [
  { value: 'opis_category', label: 'Процентные доли с 95% ДИ' },
  { value: 'opis_category_2', label: 'Оценка нормальности распределения и описание средних' },
  { value: 'graph_category', label: 'Графическое представление' },
  { value: 'graph_category_2', label: 'Графическое представление распределения' },
  { value: 'survival_chart', label: 'График выживаемости общий' },
  { value: 'survival_probability_graph', label: 'График вероятностей выживаемости по группам' },
  { value: 'groups', label: 'Сравнение групп' },
  { value: 'gender_age', label: 'Пол возраст' },
  { value: 'odds_ratio', label: 'Отношение шансов' },
  { value: 'logistic_regression', label: 'Логистическая регрессия' },
  { value: 'survival', label: 'Выживаемость' },
  { value: 'сox_regression', label: 'Регрессия Кокса' },
  { value: 'comparison_of_stages', label: 'Cравнение этапов' },
];

export const scriptListGet = () => {
  return scriptList;
};

export const scriptListGetForRole = (
  role: RoleType,
) => {
  return scriptList.filter((scriptItem) => {
    const key = 'SCRIPT_' + scriptItem.value;
    const accessValue: RoleAccessType = (role as any)[key];
                          
    return accessValue === 'ALL';
  });
}

export const translateTaskTypeHelper = (
  type: TaskScriptType,
) => {
  const task: any = scriptList.find((t) => t.value === type )
  
  if (task === undefined) {
    return "Ошибка";
  }

  return task.label;
}
