import {
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import { scriptListGet, tranlsateRoleAccessHelper } from '@app/helpers';
import { RoleAccessType } from '@app/types';

import { useMainContext } from '../../context';


export const RoleScriptsTableComponent = () => {
  const {
    roleOne,
    roleScriptsIsEditing,
  } = useMainContext();
  if (roleOne === null || roleScriptsIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="users">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>Скрипт</TableCellBrick>
            <TableCellBrick>Доступ</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          {scriptListGet().map((scriptItem) => {
            const key = 'SCRIPT_' + scriptItem.value;
            const accessValue: RoleAccessType = (roleOne as any)[key];
            return (
              <TableRowBrick
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCellBrick>{scriptItem.label}</TableCellBrick>
                <TableCellBrick>{tranlsateRoleAccessHelper(accessValue)}</TableCellBrick>
              </TableRowBrick>
            )
          })}
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};
