import { GeneralComponent } from '../general';
import { GeneralTableComponent } from '../general-table';
import { GeneralUpdateComponent } from '../general-update';
import { RoleScriptsComponent } from '../role-scripts';
import { RoleScriptsTableComponent } from '../script-access-table';
import { RoleScriptsUpdateComponent } from '../script-access-update';
import { RoleAccessComponent } from '../role-access';
import { RoleAccessTableComponent } from '../role-access-table';
import { RoleAccessUpdateComponent } from '../role-access-update';

export const MainComponent = () => {
  return (
    <>
      <GeneralComponent>
        <GeneralTableComponent />
        <GeneralUpdateComponent />
      </GeneralComponent>

      <RoleAccessComponent>
        <RoleAccessTableComponent />
        <RoleAccessUpdateComponent />
      </RoleAccessComponent>

      <RoleScriptsComponent>
        <RoleScriptsTableComponent />
        <RoleScriptsUpdateComponent />
      </RoleScriptsComponent>
    </>
  );
};
