import {
  CardContentBrick,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';
import styles from './result-view.module.scss';


export const ResultViewComponent = () => {
  const {
    taskOne,
    taskGeneralIsEditing,
  } = useMainContext();

  if (taskOne === null || taskGeneralIsEditing || taskOne.status === 'pending') return null;

  return (
    <CardDataComposition
      title="Результат выполненной задачи"
    >
      <CardContentBrick>
        {taskOne.resultText && (
          <div className={styles['result-view']} dangerouslySetInnerHTML={{__html: taskOne.resultText}}></div>
        )}
        {taskOne.resultImageS3Url && (
          <img src={taskOne.resultImageS3Url} alt="Результат выполненной задачи" />
        )}
      </CardContentBrick>
    </CardDataComposition>
  );
};
